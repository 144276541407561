import React from 'react'
// import styled from "styled-components";

const TopBar = ({shop,SystemBackGroundcolor,SystemfontColor}) => {

  const specialStyle ={
    backgroundColor:SystemBackGroundcolor,
    color: SystemfontColor
    }
  
  return (
    <div className='topBar' style={specialStyle} >
        {shop?.map(shop => (  <h4 style={specialStyle}> <span>Email:</span>{shop?.shop_email} </h4>    ))} 
        {shop?.map(shop => (  <h4 style={specialStyle}><span>Tell:</span>{shop?.shop_phone1}</h4>   ))} 
 
    </div>
  )
} 
   
export default TopBar


// const specialStyle ={
// backgroundColor:'#7d6187',
// color: '#000'
// }

// const Logout = styled.div`
//   color: #000;
//   background-color: #cacaca;
// `;