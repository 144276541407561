
import { Username1 } from "../components/Home";
export const url = "https://faizads.com/api"; 
export const username = 'faizunderwear' ; 
export const shopUsername = 'faizunderwear' ; 
export const shopID = "64e86729b75e4a77623a9f36"; 
export const redirectURL = "https://garofali.faizads.com"; 


      
export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };
  
  return headers;
};
     