import { useEffect ,useState } from 'react';
import { useNavigate } from "react-router";
import { Link,useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,  
  itemSizeName,
  itemColorName, 

} from "../slices/cartSlice";


import PayButton from "./PayButton"; 

import axios from 'axios';
import TopBar from "./TopBar";
import NavbarSamaher from "./NavbarSamaher";
import NavBar2 from "./NavBar2";  
import Footer from "./Footer";
 

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'


const Cart = () => {

  const params = useParams();
  const [data,setData]= useState([])
  const [shop,setShop]= useState([])
  const [categories,setCategories]= useState([])


  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  const [chosenColorName,setChosenColorName]= useState(localStorage.getItem("colorA"))
  const [chosenSizeName,setChosenSizeName]= useState(localStorage.getItem("sizeA"))

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };


  
  const handleSetSize = (product) => {
    dispatch(itemSizeName(product));
  };

  const handleSetColor = (product) => {
    dispatch(itemColorName(product));
  };



 

  useEffect(() => {
   
  
    axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}`)
    .then(res => {
      setData(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/shops/${params.user}`)
    .then(res => {
      setShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
    .then(res => {
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



  }, []);
 

  
  return ( 
    <>
    
    <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />
    <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />
    <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />

    
    <div className="cart-container">
      <h2>Shopping cart</h2>
      {cart.cartItems.length === 0 ? (
        <div className="cart-empty">
          <p>Your cart is empty </p>
          <div className="start-shopping">
            <Link to={`/${params.user}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg" 
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span>Start shopping </span>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="titles">
            <h3 className="product-title">Product</h3>
            <h3 className="">Price</h3>
            <h3 className="quantity">Quantity</h3>
            <h3 className="total">Subtotal</h3> 
          </div>
          <div className="cart-items">
            {cart.cartItems &&
              cart.cartItems.map((cartItem) => (
                <div className="cart-item" key={cartItem._id}> 
                  <div className="cart-product">
                    <img src={cartItem.chosenColorImage} alt={cartItem.title} />
                    <div>
                       <h3>{cartItem.title}</h3>

                       <div className="clear-btn" > <span> size: </span> {cartItem.chosenSize}  </div>
                       <div className="clear-btn" > <span> color: </span>  {cartItem.chosenColor} </div>
                      <p>{cartItem.Main_paragraph}</p>
                      <button onClick={() => handleRemoveFromCart(cartItem)}>
                      <i class="fa fa-times-circle fa-3x" aria-hidden="true"></i>
                      </button>
                    </div> 
                  </div>
                  <div className="cart-product-price">${cartItem.price}</div>
                  <div className="cart-product-quantity">
                    <button onClick={() => handleDecreaseCart(cartItem)}>
                      -
                    </button>
                    <div className="count">{cartItem.cartQuantity}</div>
                    <button onClick={() => handleAddToCart(cartItem)}>+</button>
                  </div>
                  <div className="cart-product-total-price">
                    ${cartItem.price * cartItem.cartQuantity}
                  </div>
                </div>
              ))}
          </div>
          <div className="cart-summary">
            <button className="clear-btn" onClick={() => handleClearCart()}>
            Empty the cart
            </button>
            <div className="cart-checkout">
              <div className="subtotal">
                {/* <span>Subtotal</span> */}
                <span>Total</span>
                <span className="amount">${cart.cartTotalAmount}</span>
              </div>
              {/* <p>Taxes and shipping calculated at checkout</p> */}
              <p>Including tax and shipping </p>
              {auth._id ? (
                <PayButton cartItems={cart.cartItems}  shopUsername={params.user} shopID={shop.map((s=>s._id))} />   
              ) : (
                // <button
                //   className="cart-login"
                //   onClick={() => navigate("/login")}
                // >
                //   Login to Check out
                // </button>

                <button
                className="cart-login"
                onClick={() => navigate(`/${params.user}/login`)}
                >
                Log in to complete the purchase
                </button>

              )}

              <div className="continue-shopping">
                <Link to={`/${params.user}`} >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  {/* <span>Continue Shopping</span> */}
                  <span>Continue shopping</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    <Footer shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor}/>

    </>
  );
};

export default Cart;
