import React, { Fragment,useEffect ,useState } from 'react';
import { Link,useParams } from 'react-router-dom';

import axios from 'axios';
import TopBar from "../TopBar";
import NavbarSamaher from "../NavbarSamaher";
import NavBar2 from "../NavBar2";  
import Footer from "../Footer";

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

 

const Privacy = () => {

            // const { data, error, isLoading } = useGetAllProductsQuery();
            const [data,setData]= useState([])
            const [shop,setShop]= useState([])
            const [categories,setCategories]= useState([])
        
          const params = useParams();
        
        
          useEffect(() => {
           
           
            axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}`)
            .then(res => {
              setData(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
            axios.get(`https://faizads.com/api/shops/${params.user}`)
            .then(res => {
              setShop(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
            axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
            .then(res => {
              setCategories(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
        
          }, []);
   
          
  return (
     
    <Fragment> 
    <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />

    <center> 
    <form className="login-form" >   
    <div className='FormCover'>  
    
    <div className="about-title-english">  Privacy policy   </div> 
    <p className='about-paragraph-english'>
    Thank you for using the MICHELLE Shop website. The Terms and Conditions (“Terms”) and Privacy Policy are intended to inform you of your legal rights and responsibilities in connection with the use of the MICHELLE Shop website.
    </p>
     
{/* 
    <p className='about-paragraph'>
    خصوصيتك مهمة جدا بالنسبة لنا. تنص سياسة “Lady shop“ على احترام خصوصيتك بما فيه أي معلومات قد نجمعها أثناء عمل موقعنا. لقد أنشأنا سياسة الخصوصية وقواعد استخدام الموقع لنفصح عن كيفية استخدامك لموقع “Lady shop“ وادراج أعمالك للاطلاع عليها / استخدامها ومعرفة كيفية جمعها واستخدام المحتوى والمعلومات الخاصة بك. نحن نشجعك على قراءة هذه السياسة لمساعدتك على اتخاذ قرارات صحيحة.


</p>




<p className='about-paragraph'>
يرجى العلم أن استخدامك للموقع وخدمات الخاصة به يدخلك تلقائيًا بالاتفاق مع البنود والشروط سياسة الخصوصية الخاصة بنا. نستخدم بياناتك لكي نستطيع خدمتك بشكل أفضل. سيتم استخدام بياناتك لتحسين ديناميكية الموقع وجعل ما نفعله أفضل وأكثر كفاءة. نحن نستخدم بياناتك لإنشاء قدرات وتحسينات جديدة للموقع.


</p>




<p className='about-paragraph'>
نستخدم بياناتك لجعل أعمالنا وما نقدمه من اعمال أفضل مثل قدرة محرك البحث. يجمع موقع “Lady shop“ المعلومات غير الشخصية التي تتيحها متصفحات الويب والخوادم، مثل نوع المتصفح واللغة المفضلة وتاريخ ووقت كل زيارة. هدفنا هو جمع المعلومات غير الشخصية وفهم أفضل طريقة استخدام زوارنا وعملائنا.


</p>




<p className='about-paragraph'>
نستخدم معلومات الطلب التي نجمعها عمومًا لتلبية أي طلبات يتم تقديمها عبر الموقع (بما في ذلك معلومات الدفع الخاصة بك، والترتيب للشحن، وتزويدك بالفواتير و / أو تأكيدات على الطلب).


</p> */}


 
    
 
       
        </div> 
        </form>
        </center>
        <Footer shop={shop} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor}/>

        </Fragment>

  )
}

export default Privacy 