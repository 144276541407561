import React, { Fragment,useEffect ,useState } from 'react';
import { Link,useParams } from 'react-router-dom';

import axios from 'axios';
import TopBar from "../TopBar";
import NavbarSamaher from "../NavbarSamaher";
import NavBar2 from "../NavBar2";  
import Footer from "../Footer";

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

 

 
 const RefundPolicy = () => {

  
            // const { data, error, isLoading } = useGetAllProductsQuery();
            const [data,setData]= useState([])
            const [shop,setShop]= useState([])
            const [categories,setCategories]= useState([])
        
          const params = useParams();
        
        
          useEffect(() => {
           
           
            axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}`)
            .then(res => {
              setData(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
            axios.get(`https://faizads.com/api/shops/${params.user}`)
            .then(res => {
              setShop(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
            axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
            .then(res => {
              setCategories(res.data)
            })
            .catch((err) => {
              console.log(err);
            })
        
        
        
          }, []);
   

   return (
    <Fragment> 
    <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />

<center> 
<form className="login-form" >   
<div className='FormCover'>  

<div className="about-title-english"> Return policy </div> 
<p className='about-paragraph-english'>
Return policy - details
 </p>
 

 
   
    </div> 
    </form>
    </center>
    <Footer shop={shop} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor}/>

    </Fragment>
   )
 }
 
 export default RefundPolicy 