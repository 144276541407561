import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const shopFetch = createAsyncThunk(
  "shop/shopFetch",
  async () => {
    try {
      const response = await axios.get(
        "https://chaoo-online-shop.herokuapp.com/shop"
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
 
const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: {
    [shopFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [shopFetch.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.status = "success";
    },
    [shopFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});
 
export default shopSlice.reducer;
