import { Fragment, useEffect ,useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link ,useParams } from 'react-router-dom';
import { addToCart,itemSizeName,itemColorName,itemColorImage } from "../slices/cartSlice";  
import axios from 'axios';
// import { useGetProductQuery } from "../slices/productApi";
 
import noimg from './img/noimg.png';
import spinner from './img/spinner.gif';

import TopBar from "./TopBar";
import NavbarSamaher from "./NavbarSamaher";
import NavBar2 from "./NavBar2";  
import Footer from "./Footer";

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

 
 
const noneRating = 'noneRating';
const displayRating = 'displayRating';
 
const Product = () => {

  const params = useParams();
  const [post,setPost]= useState([])
  const [validationMSG,setValidationMSG]= useState('')
  
  
  // const [sizes,setSizes]= useState([])
  // const [colors,setColors]= useState([])

  const [chosenColorName,setChosenColorName]= useState(localStorage.getItem("colorA"))
  const [chosenColorImage,setChosenColorImage]= useState(localStorage.getItem("colorImage"))
  const [chosenColorId,setChosenColorId]= useState('')


  const handleCosenColor =  (id,name,image) => {
    localStorage.setItem("colorA",name);
    localStorage.setItem("colorImage",image);
    setChosenColorName(localStorage.getItem("colorA"))
    setChosenColorImage(localStorage.getItem("colorImage"))

    setselectpicChosen('displayI')

    setselectpic1('noneDisplayI');
    setselectpic0('noneDisplayI');
    setselectpic2('noneDisplayI');
    setselectpic3('noneDisplayI');
    setselectpic4('noneDisplayI');
    setselectpic5('noneDisplayI');
    setselectpic6('noneDisplayI');
    setselectpic7('noneDisplayI');
    setselectpic8('noneDisplayI');
    setselectpic9('noneDisplayI');
    setselectpic10('noneDisplayI');


  };


  const [chosenSizeName,setChosenSizeName]= useState(localStorage.getItem("sizeA"))
  const [chosenSizeId,setChosenSizeId]= useState('')

  const handleCosenSize =  (id,name,image) => {
    localStorage.setItem("sizeA",name);
    setChosenSizeName(localStorage.getItem("sizeA"))

  };


  const ResetChoices =  () => {
    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');
    setChosenColorName('')
    setChosenColorImage('')
    setChosenSizeName('')
    };


  // const { items: product, status } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { data, error, isLoading } = useGetProductQuery();
 
  const handleAddToCart = async (post) => {

    if(chosenSizeName === "" || chosenColorName === "" ){
      setValidationMSG('Please choose your size and the color')
    }else{

    dispatch(addToCart(post));
    dispatch(itemSizeName(post));
    dispatch(itemColorName(post));
    dispatch(itemColorImage(post));
    navigate(`/${params.user}/cart`);
    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');

    }
  }; 

 
  useEffect(() => {

    axios.get(`https://faizads.com/api/posts/${params.id}`)
    .then(res => {
      setPost(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    // axios.get(`https://faizads.com/api/sizes/${params.id}`)
    // .then(res => {
     
    //   setSizes(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

 

    // axios.get(`https://faizads.com/api/colors/${params.id}`)
    // .then(res => {
     
    //   setColors(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })


  }, [post]);



  const [shop,setShop]= useState([])
  const [categories,setCategories]= useState([])

  useEffect(() => {
   
   

    axios.get(`https://faizads.com/api/shops/${params.user}`)
    .then(res => {
      setShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
    .then(res => {
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



  }, []);







       //////////////////////////////////////////////images control///////////////////////////////////////////////////
       const [selectpic0, setselectpic0] = useState('displayI');
       const [selectpic1, setselectpic1] = useState('noneDisplayI');
       const [selectpic2, setselectpic2] = useState('noneDisplayI');
       const [selectpic3, setselectpic3] = useState('noneDisplayI');
       const [selectpic4, setselectpic4] = useState('noneDisplayI');
       const [selectpic5, setselectpic5] = useState('noneDisplayI');
       const [selectpic6, setselectpic6] = useState('noneDisplayI');
       const [selectpic7, setselectpic7] = useState('noneDisplayI');
       const [selectpic8, setselectpic8] = useState('noneDisplayI');
       const [selectpic9, setselectpic9] = useState('noneDisplayI');
       const [selectpic10, setselectpic10] = useState('noneDisplayI');
       const [selectpic11, setselectpic11] = useState('noneDisplayI');
       const [selectpic12, setselectpic12] = useState('noneDisplayI');

       const [selectpicChosen, setselectpicChosen] = useState('noneDisplayI');
  
  
       const optionselectpic0 = async e => {
            setselectpic0('displayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
       const optionselectpic1 = async e => {
            setselectpic1('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic2 = async e => {
            setselectpic2('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic3 = async e => {
            setselectpic3('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic4 = async e => {
            setselectpic4('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic5 = async e => {
            setselectpic5('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic6 = async e => {
            setselectpic6('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic7 = async e => {
            setselectpic7('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic8 = async e => {
            setselectpic8('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic9 = async e => {
            setselectpic9('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic10('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
          const optionselectpic10 = async e => {
            setselectpic10('displayI');
  
            setselectpic0('noneDisplayI');
            setselectpic1('noneDisplayI');
            setselectpic2('noneDisplayI');
            setselectpic3('noneDisplayI');
            setselectpic4('noneDisplayI');
            setselectpic5('noneDisplayI');
            setselectpic6('noneDisplayI');
            setselectpic7('noneDisplayI');
            setselectpic8('noneDisplayI');
            setselectpic9('noneDisplayI');
            setselectpicChosen('noneDisplayI')
  
  
          }
  
         
   
  
       ///////////////////////////////////////contact control/////////////////////////////////////////////////
  
       const [optionContactphone1, setmyoptionContact1] = useState('noneDisplayI');
       const [optionContactphone2, setmyoptionContact2] = useState('noneDisplayI');
       const [optionContactwhatsaap, setmyoptionContactwhatsaap] = useState('noneDisplayI');
       const [optionContactemail, setmyoptionContactemail] = useState('noneDisplayI');
       const [optionContactwebsite, setmyoptionContactwebsite] = useState('noneDisplayI');
       const [optionContactreport, setmyoptionContactreport] = useState('noneDisplayI');
       
       const optionContact1 = async e => {
            setmyoptionContact1('displayI');
            setmyoptionContact2('noneDisplayI');
            setmyoptionContactwhatsaap('noneDisplayI');
            setmyoptionContactemail('noneDisplayI');
            setmyoptionContactwebsite('noneDisplayI');
            setmyoptionContactreport('noneDisplayI');
       }
  
       const optionContact2 = async e => {
            setmyoptionContact2('displayI');
  
            setmyoptionContact1('noneDisplayI');
            setmyoptionContactwhatsaap('noneDisplayI');
            setmyoptionContactemail('noneDisplayI');
            setmyoptionContactwebsite('noneDisplayI');
            setmyoptionContactreport('noneDisplayI');
       }
       const optionContact3 = async e => {
            setmyoptionContactwhatsaap('displayI');
  
            setmyoptionContact1('noneDisplayI');
            setmyoptionContact2('noneDisplayI');
            setmyoptionContactemail('noneDisplayI');
            setmyoptionContactwebsite('noneDisplayI');
            setmyoptionContactreport('noneDisplayI');
       }
       const optionContact4 = async e => {
            setmyoptionContactemail('displayI');
            setmyoptionContact1('noneDisplayI');
            setmyoptionContact2('noneDisplayI');
            setmyoptionContactwhatsaap('noneDisplayI');
            setmyoptionContactwebsite('noneDisplayI');
            setmyoptionContactreport('noneDisplayI');
       }
       const optionContact5 = async e => {
            setmyoptionContactwebsite('displayI');
  
            setmyoptionContactemail('noneDisplayI');
            setmyoptionContact1('noneDisplayI');
            setmyoptionContact2('noneDisplayI');
            setmyoptionContactwhatsaap('noneDisplayI');
            setmyoptionContactreport('noneDisplayI');
  
           
       }
  
       const optionContact6 = async e => {
            setmyoptionContactreport('displayI');
  
            setmyoptionContactwebsite('noneDisplayI');
            setmyoptionContactemail('noneDisplayI');
            setmyoptionContact1('noneDisplayI');
            setmyoptionContact2('noneDisplayI');
            setmyoptionContactwhatsaap('noneDisplayI');
           
       }
   
  
       const [displayInputs1, toggleInputs1] = useState(false);
  
       const [myoption5, setmyoption5] = useState(displayRating)
       const [myoption4, setmyoption4] = useState(displayRating)
       const [myoption3, setmyoption3] = useState(displayRating)
  
  
       const [myoption55, setmyoption55] = useState(noneRating)
       const [myoption44, setmyoption44] = useState(noneRating)
       const [myoption33, setmyoption33] = useState(noneRating)
  
      
  
  
       const option6 = async e => {
            setmyoption5(displayRating);
            setmyoption4(displayRating);
            setmyoption3(displayRating);
     
  
  
            setmyoption55(noneRating);
            setmyoption44(noneRating);
            setmyoption33(noneRating);
  
          };
  
  
       const option5 = async e => {
            setmyoption5(displayRating);
            setmyoption4(noneRating);
            setmyoption3(noneRating);
  
  
            setmyoption55(displayRating);
            setmyoption44(noneRating);
            setmyoption33(noneRating);
  
          };
  
  
          const option4 = async e => {
            setmyoption5(noneRating);
            setmyoption4(displayRating);
            setmyoption3(noneRating);
  
  
            setmyoption55(noneRating);
            setmyoption44(displayRating);
            setmyoption33(noneRating);
         
  
          };
  
          const option3 = async e => {
            setmyoption5(noneRating);
            setmyoption4(noneRating);
            setmyoption3(displayRating);
        
  
  
  
            setmyoption55(noneRating);
            setmyoption44(noneRating);
            setmyoption33(displayRating);
  
  
  
  
  
            
          };
  
  
  
      
          const [get5s,setget5s]= useState([])
          const [get4s,setget4s]= useState([])
          const [get3s,setget3s]= useState([])
          
  
   
       
       
       const [getrr,setarr]= useState([])
       const [getpp,setpp]= useState([])
  
       const [getAuth,setAuth]= useState([])
  
       const [voting,setVoting]= useState([])
       
      
  
       
  
         const [loading, setLoading] = useState(false)
       
  
  
  
          
    
          
  
  
   

  
   
   // shop click
   const postClick=(id)=> {
       axios.put('/api/posts/click/'+id)
       .then(response => {
        console.log(response.data)
         
      });
     };

    //  const FullClear =()=>{
    //   localStorage.setItem("sizeA",'');
    //   localStorage.setItem("colorA",'');
    //   localStorage.setItem("colorImage",'');
    //   setChosenColorName('')
    //   setChosenColorImage('')
    //   setChosenSizeName('')
    //  }

  return (
 
<>


<TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
  <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
  <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />


    <div className="aqle3-main" style={{marginTop:'20px'}} >
    <div className="mainword2">
  
 <center> 

    <div className="main-about"> 

    <div className="post-title">
     {post.title}  
    </div>
 
  <div style={{display:'flex'}}>

  <>  
  <div className="showDetailImages" style={{border:'1px solid #add8e6'}}>
  {post.image &&(
  <Fragment> 
   <center>
   {post.image &&(<div className='forPic1' onClick={optionselectpic0}><img className="detail-picture2" alt={post.title} src={post.image} /></div> )}
   {post.pic1 &&(<div className='forPic1' onClick={optionselectpic1}><img className="detail-picture2" alt={post.title} src={post.pic1} /></div>)}
   {post.pic2 &&(<div className='forPic1' onClick={optionselectpic2}><img className="detail-picture2" alt={post.title} src={post.pic2} /></div>)}
   {post.pic3 &&(<div className='forPic1' onClick={optionselectpic3}><img className="detail-picture2" alt={post.title} src={post.pic3} /></div>)}
   {post.pic4 &&(<div className='forPic1' onClick={optionselectpic4}><img className="detail-picture2" alt={post.title} src={post.pic4} /></div>)}
   {post.pic5 &&(<div className='forPic1' onClick={optionselectpic5}><img className="detail-picture2" alt={post.title} src={post.pic5} /></div>)}
   {post.pic6 &&(<div className='forPic1' onClick={optionselectpic6}><img className="detail-picture2" alt={post.title} src={post.pic6} /></div>)}
   {post.pic7 &&(<div className='forPic1' onClick={optionselectpic7}><img className="detail-picture2" alt={post.title} src={post.pic7} /></div>)}
   {post.pic8 &&(<div className='forPic1' onClick={optionselectpic8}><img className="detail-picture2" alt={post.title} src={post.pic8} /></div>)}
   {post.pic9 &&(<div className='forPic1' onClick={optionselectpic9}><img className="detail-picture2" alt={post.title} src={post.pic9} /></div>)}
   {post.pic10 &&(<div className='forPic1' onClick={optionselectpic10}><img className="detail-picture2" alt={post.title} src={post.pic10} /></div>)}
   </center>
 </Fragment>
  )}
</div>
</>





   <div  className="main-pic">    
       {post.image?(
       
      <div className={selectpic0}><img className="detail-picture" alt={post.title} src={post.image} /></div> 
      
       ):(
             
           
           <div className={selectpic0}><img className="detail-no-picture" alt={post.title} src={spinner} /> </div> 
         

       )}    

      <a> 
      <div className={selectpic1}><img className="detail-picture" alt={post.title} src={post.pic1} /></div> 
      <div className={selectpic2}><img className="detail-picture" alt={post.title} src={post.pic2} /></div> 
      <div className={selectpic3}><img className="detail-picture" alt={post.title} src={post.pic3} /></div> 
      <div className={selectpic4}><img className="detail-picture" alt={post.title} src={post.pic4} /></div>
      <div className={selectpic5}><img className="detail-picture" alt={post.title} src={post.pic5} /></div> 
      <div className={selectpic6}><img className="detail-picture" alt={post.title} src={post.pic6} /></div> 
      <div className={selectpic7}><img className="detail-picture" alt={post.title} src={post.pic7} /></div> 
      <div className={selectpic8}><img className="detail-picture" alt={post.title} src={post.pic8} /></div> 
      <div className={selectpic9}><img className="detail-picture" alt={post.title} src={post.pic9} /></div> 
      <div className={selectpic10}><img className="detail-picture" alt={post.title} src={post.pic10} /></div> 
      <div className={selectpicChosen}><img className="detail-picture"  src={chosenColorImage} /></div> 

      </a>
      

  




  <center>  
  <div className="showDetailImagesMobile2">
  {post.image &&(
  <Fragment> 
   <center>
   {post.image &&(<div className='forPic1' onClick={optionselectpic0}><img className="detail-picture2" alt={post.title} src={post.image} /></div> )}
   {post.pic1 &&(<div className='forPic1' onClick={optionselectpic1}><img className="detail-picture2" alt={post.title} src={post.pic1} /></div>)}
   {post.pic2 &&(<div className='forPic1' onClick={optionselectpic2}><img className="detail-picture2" alt={post.title} src={post.pic2} /></div>)}
   {post.pic3 &&(<div className='forPic1' onClick={optionselectpic3}><img className="detail-picture2" alt={post.title} src={post.pic3} /></div>)}
   {post.pic4 &&(<div className='forPic1' onClick={optionselectpic4}><img className="detail-picture2" alt={post.title} src={post.pic4} /></div>)}
   {post.pic5 &&(<div className='forPic1' onClick={optionselectpic5}><img className="detail-picture2" alt={post.title} src={post.pic5} /></div>)}
   {post.pic6 &&(<div className='forPic1' onClick={optionselectpic6}><img className="detail-picture2" alt={post.title} src={post.pic6} /></div>)}
   {post.pic7 &&(<div className='forPic1' onClick={optionselectpic7}><img className="detail-picture2" alt={post.title} src={post.pic7} /></div>)}
   {post.pic8 &&(<div className='forPic1' onClick={optionselectpic8}><img className="detail-picture2" alt={post.title} src={post.pic8} /></div>)}
   {post.pic9 &&(<div className='forPic1' onClick={optionselectpic9}><img className="detail-picture2" alt={post.title} src={post.pic9} /></div>)}
   {post.pic10 &&(<div className='forPic1' onClick={optionselectpic10}><img className="detail-picture2" alt={post.title} src={post.pic10} /></div>)}

   </center>
 </Fragment>
  )}
</div>
</center>

 
     {/* <a  href={post.video}  target="_blank"  className="button-video-display">  Product Video <i  className="fa fa-youtube-play fa-1x" aria-hidden="true"></i> </a>
   <a href={post.websitelink} className="button-details" target="_blank"  > Offer Price </a>  */}

 
 
   <Fragment>
    <center> 
       

        {chosenSizeName === "" ?(

        <div style={{display:'flex',marginTop:'5px', alignItems: 'center'}}>
        <>choose the size:</>
        { post?.sizes?.map(size =>(
        <>   
        {size.image ?(
         <button className='forPic1' onClick={()=>handleCosenSize(size?._id,size?.size_AR_name)} >
        <img className="detail-picture2" alt={size?.size_AR_name} src={size?.image} />
        </button> 
        
         ):(
          <button className='forPic1' onClick={()=>handleCosenSize(size?._id,size?.size_AR_name)} >
          {size?.size_AR_name}
          </button> 

         )}
         </>
     
      ))}
       </div>

):(
  <> 
  <button className='forPicOneColorSizeShow' >
  {chosenSizeName}
  </button>
  <i style={{color:'green'}} class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
     </>
  )}


       </center>
      </Fragment> 







      

         <Fragment>
        <center>
       

        {chosenColorName === "" ?(

        <div style={{display:'flex',marginTop:'5px', alignItems: 'center'}}>
        <>choose the color:</>
        { post?.colors?.map(color =>(
        <>   
        {color?.image ?(
        <button className='forPic1' onClick={()=>handleCosenColor(color?._id,color?.color_AR_name,color?.image)} >
        <img className="detail-picture2" alt={color?.color_AR_name} src={color?.image} />
        </button> 
         ):(
          <button className='forPic1' onClick={()=>handleCosenColor(color?._id,color?.color_AR_name,color?.image)} >
          {color?.color_AR_name}
          </button> 

         )}
         </>
     
      ))}
       </div>
        ):(

         <> 
          <button className='forPicOneColorSizeShow' >
          <img className="detail-picture2"  src={chosenColorImage} />
          </button>
          <i style={{color:'green'}} class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          </>

        )}

       </center>
      </Fragment> 

     
      <button className='forPicOneColorSizeShow' onClick={ResetChoices}>
      <i class="fa fa-refresh fa-3x" aria-hidden="true"></i>
          </button>

  {validationMSG && <div className='alert-danger'> {validationMSG} </div> }

          
  <button   className="button-buy" 
  onClick={() => handleAddToCart(post)}
  > 
  ${post.price} <i class="fa fa-shopping-cart fa-3x" aria-hidden="true"></i> Add to cart
   </button> 



</div>



 


    </div>
     


    </div>
    </center>


    </div>
    </div>

    <Footer shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor}/>

    </>
  );
};

export default Product;
