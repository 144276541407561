import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  //  chosenSize:localStorage.getItem("sizeA"),
  //  chosenColor:localStorage.getItem("colorA")
};
 
const cartSlice = createSlice({
  name: "cart",
  initialState, 
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      ); 

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
       

        };
        toast.info("Increase the amount of product", {
          position: "bottom-left",
        });
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProductItem);
        toast.success("product has been added to the cart", {
          position: "bottom-left",
        });
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },


    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info("decrement product quantity", {
          position: "bottom-left",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item._id !== action.payload._id
        );

        state.cartItems = nextCartItems;

        toast.error("product has been removed from the cart", {
          position: "bottom-left",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },



    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem._id === action.payload._id) {
          const nextCartItems = state.cartItems.filter(
            (item) => item._id !== cartItem._id
          );

          state.cartItems = nextCartItems;

          toast.error("product has been removed from the cart", {
            position: "bottom-left",
          });
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        return state;
      });
    },

    
    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    clearCart(state, action) {
      state.cartItems = [];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error("the cart has been cleared", { position: "bottom-left" });
    },



    itemSizeName(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item._id === action.payload._id
      ); 
      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          chosenSize:localStorage.getItem("sizeA"),
        };
    }
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
  },



  itemColorName(state, action) {
    const existingIndex = state.cartItems.findIndex(
      (item) => item._id === action.payload._id
    ); 
    if (existingIndex >= 0) {
      state.cartItems[existingIndex] = {
        ...state.cartItems[existingIndex],
        chosenColor:localStorage.getItem("colorA"),
        
      };
  }
  localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
},

itemColorImage(state, action) {
  const existingIndex = state.cartItems.findIndex(
    (item) => item._id === action.payload._id
  ); 
  if (existingIndex >= 0) {
    state.cartItems[existingIndex] = {
      ...state.cartItems[existingIndex],
      chosenColorImage:localStorage.getItem("colorImage")
    };
}
localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
}





    
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart,itemSizeName,itemColorName,itemColorImage } =
  cartSlice.actions;

export default cartSlice.reducer;
