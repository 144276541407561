import React, { Fragment, useEffect ,useState } from 'react';

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { logoutUser } from "../slices/authSlice";
import { toast } from "react-toastify";
import ladyicon from './img/lady-shop-icon.jpg'



import ff from './img/mmdw.jpg';
import { useTranslation } from 'react-i18next';
  
import axios from 'axios';
 
const mySidenav0 = 'sidenavnone';
const sidenav = 'sidenav';

const Navbar = ({shopID,shop,shopUsername}) => {

 

    const dispatch = useDispatch();
    const { cartTotalQuantity } = useSelector((state) => state.cart);
    const auth = useSelector((state) => state.auth);

  const [t, i18next] = useTranslation()

  const [mySidenav, setmySidenav] = useState(mySidenav0)

  const [categories11,setCategory11]= useState([])
 
  const openMenu = async e => {
 
    setmySidenav(sidenav);
  };

  

  const closeMenu = async e => {
 
    setmySidenav(mySidenav0);

    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');

  };




  
  useEffect(() => {
   
    axios.get('/api/categories/public')
    .then(res => {
      console.log(res);
    setCategory11(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




  }, [categories11]);

  
  const FullClear =()=>{
    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');
 
   }
     
 
  const authLinks = (
   
        <ul className="topnavSYS" >
        <div className="logoContent" >
        <Link  onClick={FullClear} to={`/${shopUsername}`}>
        {shop?.map(shop => ( <img className="logo"   src={shop?.shop_logo_img} /> ))} 
       
        </Link>
        </div>


       
 
    
        
<div id="mySidenav"   className={mySidenav}>
 
 <button onClick={closeMenu} className="closebtn" >&times;</button>
 {/* {categories11.map(catego=>(
<Fragment> 
  <a href={`/displayCategoryItems/${catego._id}#/${catego.c_name}`} > 
 <span><img className="" src={catego.image} style={{width:'50px' , height:'50px', marginRight:'20px'}}/></span>
 {catego.c_name} </a> 
  </Fragment>
 ))} */}  

  <Link  to={`/${shopUsername}`} onClick={closeMenu}> Home  {' '} <i class="fa fa-home fa-1x" aria-hidden="true" ></i> </Link>


 <Link to= {`/${shopUsername}/company/refund-policy`}  onClick={closeMenu}> Return policy   <i class="fa fa-exchange fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/customer-support`}  onClick={closeMenu}>  Customers service  <i class="fa fa-user-circle-o fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/privacy`} onClick={closeMenu}> Privacy policy   <i class="fa fa-lock fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/cancelation-and-refund-policy`} onClick={closeMenu}> Return and exchange   <i class="fa fa-exchange fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/shipping-and-delivery-policy`} onClick={closeMenu}> Shipping and delivery   <i class="fa fa-truck fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/about`} onClick={closeMenu}> About   <i class="fa fa-info-circle fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/terms`} onClick={closeMenu}> Terms and Conditions   <i class="fa fa-sign-in fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/contact`} onClick={closeMenu}> Connect with us   <i class="fa fa-phone-square fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/customer-reviews`} onClick={closeMenu}> testimonials   <i class="fa fa-users fa-1x" aria-hidden="true"></i> {' '}</Link>

        <button 
         onClick={() => {
            dispatch(logoutUser(null));
            toast.warning("Logged out!", { position: "bottom-left" });
          }}
          className="closebtn" style={{backgroundColor:'#fff'}}
        >
          <span className='LogoutColor'>Logout {' '}<i class="fa fa-power-off fa-1x" aria-hidden="true"></i></span>
          <i className='fas fa-sign-out-alt' />
          </button>
    





</div>
 
      {/* <li className="left">
      <Link to='/'>
        <img src={ss} width="60px" height="40px"/>
        </Link>
        </li> */} 
 

<li className="">
  
  
  <Link to={`/${shopUsername}/cart`}>
    <div className="nav-bag">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        fill="currentColor"
        className="bi bi-handbag-fill"
        viewBox="0 0 16 16"
      >
        <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
      </svg>
      <span className="bag-quantity">
        <span>{cartTotalQuantity}</span>
      </span>
    </div> 
  </Link>

  </li>
{' '}







  
  
      <li>
      <button className="buttonNav" onClick={openMenu}>  &#9776;   </button>
      </li>

  
 
      

  
  
      <li className=" ">
        <a 
        className='LogoutColor'
          onClick={() => {
            dispatch(logoutUser(null));
            toast.warning("Logged out!", { position: "bottom-left" });
          }}         
        >
          
          <span   ><i class="fa fa-power-off" aria-hidden="true"></i></span>
          {' '} <i className='fas fa-sign-out-alt' />
        </a>
      </li> 













  
    </ul>
  );
  
  const guestLinks = (
    <ul className="topnavSYS" >
      
        <div className="logoContent">
        <Link  onClick={FullClear} to={`/${shopUsername}`}>
        {shop?.map(shop => ( <img className="logo"   src={shop?.shop_logo_img} /> ))} 
        </Link>

        {/* <Link to={`/`}>
        {shop?.map(shop => ( <img className="logo"   src='https://cc-west-usa.oss-accelerate.aliyuncs.com/a22cff7e-550a-4076-a108-12c294deafb9.jpg?x-oss-process=image/resize,m_pad,w_400,h_400' /> ))} 
        </Link> */}


        {/* <Link to={`/`}>
        {shop?.map(shop => ( <img className="logo"   src='https://cc-west-usa.oss-accelerate.aliyuncs.com/fdb7b940-37d2-482c-8d7d-4b6f40194ffb.jpg?x-oss-process=image/resize,m_pad,w_400,h_400' /> ))} 
        </Link> */}


        </div>

   
         
        
<button className="buttonNav" onClick={openMenu} >  &#9776;   </button>
 

<div id="mySidenav"   className={mySidenav}>
 <center> 
 <button onClick={closeMenu} className="closebtn" >&times;</button>
 </center>
 {/* {categories11.map(catego=>(

<Fragment> 

<a href={`/displayCategoryItems/${catego._id}#/${catego.c_name}`} > 
<span><img className="" src={catego.image} style={{width:'50px' , height:'50px', marginRight:'20px'}}/></span>
{catego.c_name} </a>

</Fragment>

 ))} */}

 

<Link to= {`/${shopUsername}/company/refund-policy`}  onClick={closeMenu}> Return policy   <i class="fa fa-exchange fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/customer-support`}  onClick={closeMenu}>  Customers service  <i class="fa fa-user-circle-o fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/privacy`} onClick={closeMenu}> Privacy policy   <i class="fa fa-lock fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/cancelation-and-refund-policy`} onClick={closeMenu}> Return and exchange   <i class="fa fa-exchange fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/shipping-and-delivery-policy`} onClick={closeMenu}> Shipping and delivery   <i class="fa fa-truck fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/about`} onClick={closeMenu}> About   <i class="fa fa-info-circle fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/terms`} onClick={closeMenu}> Terms and Conditions   <i class="fa fa-sign-in fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/contact`} onClick={closeMenu}> Connect with us   <i class="fa fa-phone-square fa-1x" aria-hidden="true"></i> {' '}</Link>
 <Link to= {`/${shopUsername}/company/customer-reviews`} onClick={closeMenu}> testimonials   <i class="fa fa-users fa-1x" aria-hidden="true"></i> {' '}</Link>


</div>

    
 
     <li className="">
        <Link onClick={FullClear}  to={`/${shopUsername}/register`}>
          <span className='hide-sm'>Signup </span>
          {' '}<i class="fa fa-user-o fa-1x" ></i> 
          </Link>
      </li> 
 
 
      <li className="">
        <Link onClick={FullClear}  to={`/${shopUsername}/login`}> 
        <span className='hide-sm'>Login </span>
        {' '}<i class="fa fa-sign-in fa-1x" aria-hidden="true"></i> 
        </Link>
      </li>
 
 
      <li className="">
  
      <Link to={`/${shopUsername}/cart`}>
        <div className="nav-bag">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-handbag-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
          </svg>
          <span className="bag-quantity">
            <span>{cartTotalQuantity}</span>
          </span>
        </div> 
      </Link>

      </li>

 



    </ul>





  );
 
  return (
    <Fragment>
    <center> 
    
        <Fragment>{auth._id? authLinks : guestLinks}</Fragment>
      
      </center>
    </Fragment>
  );
};

 
 
export default Navbar



const AuthLinks = styled.div`
  a {
    &:last-child {
      margin-left: 0rem;
    }
  }
`;

const Logout = styled.div`
  color: white;
  cursor: pointer;
`;
