import { useEffect ,useState } from 'react';  
import { Link,useParams } from 'react-router-dom';

import axios from "axios";
import { useSelector } from "react-redux";
import { url,shopID,shopUsername,redirectURL,username } from "../slices/api"; 



const PayButton = ({ cartItems,redirectURL }) => {

  // const params = useParams();
  // const [shop,setShop]= useState([])

  // const shopUsername=params.user
  // const shopID = shop.map((s=>s._id))
  
const user = useSelector((state) => state.auth);
   
  const handleCheckout = () => { 
    axios
      .post(`${url}/Shopperstripe/create-checkout-session`, {
        cartItems,
        userId: user._id,
        shopUsername:shopUsername,
        shopID: shopID,
        redirectURL:`https://shop.faizads.com/${shopUsername}` 
      })
      .then((response) => {
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      })
      .catch((err) => console.log(err.message));
  };





  // useEffect(() => {

  //   axios.get(`https://faizads.com/api/shops/${params.user}`)
  //   .then(res => {
  //     setShop(res.data)
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  // }, []);
 

 
  return (
    <>
      <button onClick={() => handleCheckout()}> Complete the purchase  </button>
    </>
  );
};

export default PayButton;
