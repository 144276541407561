import  { Fragment } from 'react';

import { Link,useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import smalllogo from './img/favicon2.png';
import Gpay from './img/payment_methods.jpg';


 
  const Footer = ({shop,SystemBackGroundcolor,SystemfontColor,shopUsername}) => {
    // const [t, i18next] = useTranslation()
    const params = useParams();

    const specialStyle ={
      backgroundColor:SystemBackGroundcolor,
      color: SystemfontColor
      }
      
    return (
      <Fragment>
<footer className="myFooter" style={specialStyle}>

<center>
<div className='vertical-menu-wrapper' style={specialStyle}>




<ul className='vertical-menu-about'>
<li>  
 
{shop?.map(shop => (  <img    src={shop?.shop_logo_img}  width={200} style={{borderRadius:'15px'}}/>    ))} 
</li>
<li> 
{shop?.map(shop => (  <a style={specialStyle}> {shop?.shop_name} </a>   ))}


</li>
{/* <li>  <a>  الرياض المملكة العربية السعودية </a> </li> */}
{/* <li>  <a> USA Casper, WY 82601</a> </li> */}
{/* <li>   <a> <i class="fa fa-phone-square fa-1x" aria-hidden="true"></i> 0558692541 </a> </li> */}
</ul>  


 
 

<ul className='vertical-menu' >
<li>   <div className='vertical-menu-active' style={{backgroundColor:SystemfontColor}}> </div> </li>
 <li><Link to={`/${params.user}/company/about`}  style={{color:SystemfontColor}}> About    </Link></li>
 <li><Link to={`/${params.user}/company/terms`}  style={{color:SystemfontColor}}> Terms and Conditions  </Link></li>
 <li><Link to={`/${params.user}/company/contact`}  style={{color:SystemfontColor}}> Connect with us  </Link></li>
 <li><Link to={`/${params.user}/company/customer-reviews`} 
 style={{color:SystemfontColor}}> Testimonials  </Link></li>
</ul>
 

  
 
<ul className='vertical-menu'>

<li> <div className='vertical-menu-active' style={{backgroundColor:SystemfontColor}}> </div> </li>

<li><Link to={`/${params.user}/company/refund-policy`} style={{color:SystemfontColor}}> Return policy  </Link></li>

 <li><Link to={`/${params.user}/company/customer-support`} style={{color:SystemfontColor}}>  Customers service  </Link></li>

 <li><Link to={`/${params.user}/company/privacy`} style={{color:SystemfontColor}}> Privacy policy   </Link></li>

 <li><Link to={`/${params.user}/company/cancelation-and-refund-policy`} style={{color:SystemfontColor}}> Return and exchange  </Link></li>

 <li><Link to= {`/${params.user}/company/shipping-and-delivery-policy`} style={{color:SystemfontColor}}> Shipping and delivery  </Link></li>
</ul>






{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'>
<li>   <div className='vertical-menu-active'> </div> </li>
<li>  <a href="/company/about">About us </a></li>
<li>  <a href="/company/contact">Contact</a> </li>
<li>  <a href="/company/privacy">Privacy</a> </li>
<li>  <a href="/company/terms">Terms and Conditions</a></li>
</ul>
)} */}



 
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active' style={{backgroundColor:SystemfontColor}}>  </div> </li>
<li> <Link to= {`/${params.user}/register`} style={{color:SystemfontColor}}> Signup </Link> </li>

<li> <Link to={`/${params.user}/login`} style={{color:SystemfontColor}}>Login</Link> </li>
{/* <li> <a href="/membership/prices" style={{color:SystemfontColor}}>باقات العضوية</a> </li>
<li> <a href="/user/registrationMethod" style={{color:SystemfontColor}}>طريقة التسجيل</a> </li> */}
</ul>
 


{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
<li> <a href="/user/createAccount">Create account </a> </li>
<li> <a href="/user/login">Login</a> </li>
<li> <a href="/membership/prices">Membership</a> </li>
<li> <a href="/user/registrationMethod">How it works </a> </li>
</ul>
)} */}





{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
<li> <a href="/company/affiliate">Affiliates </a> </li>
<li> <a href="/#">Blog</a> </li>
<li> <a href="/#">Events</a> </li>
<li> <a href="/#">News</a> </li>
</ul>
)} */}
 
 
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active' style={{backgroundColor:SystemfontColor}}>  </div> </li>
{/* <li> <a href="/company/affiliate">انضم إلى برنامج الشركاء </a> </li> */}
<li> <Link to="/#"  style={{color:SystemfontColor}}>Blog</Link> </li>
<li> <Link to="/#" style={{color:SystemfontColor}} >Occasions </Link> </li>
<li> <Link to="/#" style={{color:SystemfontColor}}>News</Link> </li>
</ul>
 













</div>
</center>
         
<center> 
<div className="paymentMethodeSectionFooter">  
{/* <i class="fa fa-cc-amex fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-paypal fa-3x" aria-hidden="true"></i> */}

{/* <i class="fa fa-cc-stripe fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-mastercard fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-visa fa-3x" aria-hidden="true"></i> */}

 <img src={Gpay} />

  

</div>
</center>

 

<footer className="myFooter2" style={specialStyle}>
<div className="copyrighttext" style={specialStyle}>  
            © 2023 {shop?.map(shop => (  <a> {shop?.shop_name} </a>   ))} online shop - All Rights Reserved.

</div>

  </footer> 
</footer>
     
      </Fragment>
    );
  };




 

export default Footer;




