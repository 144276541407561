import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
 
import { ToastContainer } from "react-toastify";
import Home from "./components/Home";
  
import Category from "./components/Category";

// import NavBar from "./components/NavBar";
// import TopBar from "./components/TopBar";
// import NavbarSamaher from "./components/NavbarSamaher";
// import NavBar2 from "./components/NavBar2";  
// import Footer from "./components/Footer";

import NotFound from "./components/NotFound";

import Cart from "./components/Cart";
 
import Product from "./components/Product";

import "react-toastify/dist/ReactToastify.css";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./slices/authSlice";
import CheckoutSuccess from "./components/CheckoutSuccess";



import About from "./components/company/about";
import Contact from "./components/company/contact";
import Privacy from "./components/company/privacy";
import Terms from "./components/company/terms";

 
import RefundPolicy from "./components/company/refund-policy";
import CustomerSupport from "./components/company/customer-support";
import CancelationAndRefundPolicy from "./components/company/cancelation-and-refund-policy";
import ShippingAndDeliveryPolicy from "./components/company/shipping-and-delivery-policy";
import CustomerReviews from "./components/company/customer-reviews";
 

import { useGetShopQuery } from "./slices/shopApi"; 
import { username } from "./slices/api";
 


const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#000'


// const SystemBackGroundcolor = 'blue';
// const SystemfontColor='#fff'

 
 
 function App() {
 
 
  const dispatch = useDispatch();
  const { data, error, isLoading } = useGetShopQuery();


  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  return (
    <div className="App">
      
      <BrowserRouter>
        <ToastContainer />
        {/* <TopBar shop={data} SystemBackGroundcolor={SystemBackGroundcolor} SystemfontColor={SystemfontColor} />
        <NavbarSamaher shop={data}  SystemBackGroundcolor={SystemBackGroundcolor} SystemfontColor={SystemfontColor} /> */}
       
        {/* <NavBar /> */}
       
        {/* <NavBar2 SystemBackGroundcolor={SystemBackGroundcolor} SystemfontColor={SystemfontColor} /> */}
  
        <div className="content-container">
          <Routes>
            <Route path="/:user" element={<Home />} />
            <Route path="/:user/cart" element={<Cart />} />
            <Route path="/:user/checkout-success" element={<CheckoutSuccess />} />
              
            <Route path="/:user/register" element={<Register />} />
            <Route path="/:user/login/" element={<Login />} />

 
             

            <Route path="/:user/posts/:id" element={<Product />} /> 
            <Route path={`/:user/category/special-shops/:id`} element={<Category />} /> 
            

            <Route path="/:user/company/about" element={<About shop={data} />} /> 
            <Route path="/:user/company/contact" element={<Contact shop={data}/>} />
            <Route path="/:user/company/privacy" element={<Privacy shop={data} />} />
            <Route path="/:user/company/terms" element={<Terms shop={data} />} />

        

            <Route path="/:user/company/refund-policy" element={<RefundPolicy shop={data} />} />
            <Route path="/:user/company/customer-support" element={<CustomerSupport />} />
            <Route path="/:user/company/cancelation-and-refund-policy" element={<CancelationAndRefundPolicy shop={data} />} />
            <Route path="/:user/company/shipping-and-delivery-policy" element={<ShippingAndDeliveryPolicy shop={data} />} />
            <Route path="/:user/company/customer-reviews" element={<CustomerReviews shop={data} />} />
          

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      {/* <Footer shop={data} SystemBackGroundcolor={SystemBackGroundcolor} SystemfontColor={SystemfontColor}/> */}
      </BrowserRouter>
    
    </div>
  );
}

export default App;
