import React, { Fragment,useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// import { useGetCategoriesQuery } from "../slices/categoriesApi"; 
// import { username } from "../slices/api";
 
 
      
function NavBar2 ({SystemBackGroundcolor,SystemfontColor,categories,shopUsername}) {

  const specialStyle ={
    backgroundColor:SystemBackGroundcolor,
    color: SystemfontColor
    }


 
	const dispatch = useDispatch();
	const { cartTotalQuantity } = useSelector((state) => state.cart);
	const auth = useSelector((state) => state.auth);

	// const { data, error, isLoading } = useGetCategoriesQuery();


	const [mobileMenu,setMobileMenu] = useState('element-none')
	const [closingButton,setClosingButton] = useState('element-none')
	const [hamburgurButton,setHamburgurButton] = useState('element-block')
 
	function handleHamburger (){
		setMobileMenu('element-block')
		setClosingButton('element-block')
		setHamburgurButton('element-none')
	}

	function handlClosing (){
		setMobileMenu('element-none')
		setClosingButton('element-none')
		setHamburgurButton('element-block')

    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');
	}


  const FullClear =()=>{
    localStorage.setItem("sizeA",'');
    localStorage.setItem("colorA",'');
    localStorage.setItem("colorImage",'');
 
   }

  return (
  
        <Fragment>
       
          <div class="controls" style={specialStyle}>
		 <div className={hamburgurButton}>
          <div class="hamburgur-wrapper" onClick={handleHamburger}>
          <div class="hamburgur" ></div>
          <div class="hamburgur"></div>
          <div class="hamburgur"></div>
          </div>  
		  </div>
		  
		  <div className={closingButton} > 
          <div class="closing-wrapper" onClick={handlClosing} style={specialStyle}>
          <div class="closing"> 
            &times;
          </div>
          </div> 
		  </div>

      <div className={hamburgurButton}> <div class="menu-title" onClick={handleHamburger} style={specialStyle}></div>   </div>
      <div className={closingButton}> <div class="menu-title" onClick={handlClosing} style={specialStyle}></div>   </div>

		  <Link to={`/${shopUsername}/cart`}>  
     <div class="menu-title">
           
			 
			<div className="nav-bag">
			<svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            fill="currentColor"
            className="bi bi-handbag-fill"
            viewBox="0 0 20 20"
			style={{color:'#111'}}
          >
            <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
          </svg>
          <span className="bag-quantity">
            <span>{cartTotalQuantity}</span>
          </span>
		</div>
		

          </div> 
		  </Link>
        
          </div> 
  
  
  <ul class="topnav3" > 
  <li ><Link onClick={FullClear} to={`/${shopUsername}`}  >Home</Link></li>
  {categories?.map((element) => (
	<li><Link onClick={FullClear} to= {`/${shopUsername}/category/special-shops/${element?._id}`} >{element?.c_EN_name}</Link></li>
  ))}
	</ul>
	
 
	  
  <div className={mobileMenu}> 
	<ul className="topnav3-mobile"> 
  <li><Link to={`/${shopUsername}`} onClick={handlClosing} >Home</Link></li>
	{categories?.map((element) => (
	<li><Link   to= {`/${shopUsername}/category/special-shops/${element?._id}`} onClick={handlClosing}>{element?.c_EN_name}</Link></li>
  ))}
	</ul>
	</div>

 





        </Fragment>
  )
}

export default NavBar2