import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";



import productsReducer, { productsFetch } from "./slices/productsSlice";
import productReducer, { productFetch } from "./slices/productSlice";
import shopReducer, { shopFetch } from "./slices/shopSlice";
import categoriesReducer, { categoriesFetch } from "./slices/categoriesSlice";

import cartReducer, { getTotals } from "./slices/cartSlice";


import authReducer from "./slices/authSlice";
import { productsApi } from "./slices/productsApi";
import { productApi } from "./slices/productApi";
import { shopApi } from "./slices/shopApi";
import { categoriesApi } from "./slices/categoriesApi";
 
    
const store = configureStore({
  reducer: {
    products: productsReducer,
    product: productReducer,
    shop:shopReducer,
    categories:categoriesReducer,
    cart: cartReducer,
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [shopApi.reducerPath]: shopApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
  },
  
    // middleware: (getDefaultMiddleware) =>
    //getDefaultMiddleware().concat(productsApi.middleware),
    //getDefaultMiddleware().concat(ringsApi.middleware),
});
 
store.dispatch(productsFetch());
store.dispatch(productFetch());
store.dispatch(shopFetch());
store.dispatch(categoriesFetch());
store.dispatch(getTotals());
 

 
 

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}> 
      <App />
      </Provider> 
  </React.StrictMode>,
  document.getElementById("root")
);
