import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { Link,useParams } from 'react-router-dom';
import { StyledForm } from "./StyledForm";



import axios from 'axios';
import TopBar from "../TopBar";
import NavbarSamaher from "../NavbarSamaher";
import NavBar2 from "../NavBar2";  
import Footer from "../Footer";
 

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

  
const Login = () => {

  const params = useParams();
  const [data,setData]= useState([])
  const [shop,setShop]= useState([])
  const [categories,setCategories]= useState([])


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth._id) {
      navigate(`/${params.user}/cart`);
    }
  }, [auth._id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(user);
    dispatch(loginUser(user));
  };



   

  useEffect(() => {
   
  
    axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}`)
    .then(res => {
      setData(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/shops/${params.user}`)
    .then(res => {
      setShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
    .then(res => {
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



  }, []);
 



  return (
    <>

   <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />
    <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />
    <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor} />


      <StyledForm onSubmit={handleSubmit}>
        <h2>Login</h2>
        <input
          type="email"
          placeholder="email"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <input
          type="password"
          placeholder="password"
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        <button>
          {auth.loginStatus === "pending" ? "... processing " : "Login"}
        </button>
        {auth.loginStatus === "rejected" ? <p>{auth.loginError}</p> : null}
      </StyledForm>

      <Footer shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))}  SystemfontColor={SystemfontColor}/>

    </>
  );
};

export default Login;
