import { Fragment, useEffect ,useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link ,useParams } from 'react-router-dom';
import { addToCart } from "../slices/cartSlice"; 
import { useGetAllProductsQuery } from "../slices/productsApi";
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
 
import axios from 'axios';

 
import noimg from './img/noimg.png';
import spinner from './img/spinner.gif';


import TopBar from "./TopBar";
import NavbarSamaher from "./NavbarSamaher";
import NavBar2 from "./NavBar2";  
import Footer from "./Footer";

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

// import { username } from "../slices/api";

 
const Home = ({}) => {
  const { items: products, status } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { data, error, isLoading } = useGetAllProductsQuery();



  const settings = {
    className: "carousel-item-b swiper-slide",
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
 
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    navigate("/cart");
  };


  const [posts,setPosts]= useState([])

  
  useEffect(() => {

    axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}/special-shops/${params.id}`)
    .then(res => {
      setPosts(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
  
  }, [posts]);





  
  const [shop,setShop]= useState([])
  const [categories,setCategories]= useState([])

  useEffect(() => {
   
   

    axios.get(`https://faizads.com/api/shops/${params.user}`)
    .then(res => {
      setShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
    .then(res => {
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



  }, []);

 

return posts.length<1 ?(

 <center> <img src={spinner} /> </center>
):(
<> 
 
  <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
  <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
  <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />


<div className="home-container">
{status === "success" ? (
<>
 {/* <div>
<h2 className="BigTitle1">... وصل حديثا</h2>
        {posts?.length ? (
        <Slider {...settings}>
          {posts.map((element) => (
            <> 
           <div key={element._id} className="product">
        
                <Link  to={`/posts/${element._id}`}> 
                        <img
                          src={element.image}
                          alt=""
                          className="" 
                          width={200}
                          height={200}
                        />
                        </Link>
                        <h3>{element.title}</h3> 
                         
          </div>
   
          </>
            ))}
          </Slider>
            ) : null}
      </div> */}
 


      <hr className='hrLine' />

        <div className="products">
          {posts &&
            posts?.map((product) => (
               
              <div key={product._id} className="product">
                
                {/* <h3> عنوان المنتح من 4 كلمات </h3> */}
                <Link  to={`/${params.user}/posts/${product._id}`}> 
                <img src={product.image} alt={product.title} />
                </Link>
                <h3>{product.title}</h3> 
                <div className="details">
                 {/* <span>{product.Main_paragraph}</span>
                  <span className="price">${product.price}</span>  */}
  

                <button onClick={() =>navigate(`/${params.user}/posts/${product._id}`)} style={{backgroundColor:'gray',color:'#fff'}}>
                 ${product.price}
                </button>

                <button onClick={() => navigate(`/${params.user}/posts/${product._id}`)}>
                  Buy now <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                </button>


                </div> 



              </div>

            ))}
        </div>
      </>
    ) : status === "pending" ? (
      <p>Loading...</p>
    ) : (
      <p>Unexpected error occured...</p>
    )}



 


  </div>

  <Footer shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor}/>

  </>
  );
};

export default Home;
