import React, { Fragment,useEffect ,useState } from 'react';
import { Link,useParams } from 'react-router-dom';

import axios from 'axios';
import TopBar from "../TopBar";
import NavbarSamaher from "../NavbarSamaher";
import NavBar2 from "../NavBar2";  
import Footer from "../Footer";

const SystemBackGroundcolor = '#fff3e0';
const SystemfontColor='#fff'

 

const CustomerSupport = () => {
          // const { data, error, isLoading } = useGetAllProductsQuery();
          const [data,setData]= useState([])
          const [shop,setShop]= useState([])
          const [categories,setCategories]= useState([])
      
        const params = useParams();
      
      
        useEffect(() => {
         
         
          axios.get(`https://faizads.com/api/posts/postsofshop/${params.user}`)
          .then(res => {
            setData(res.data)
          })
          .catch((err) => {
            console.log(err);
          })
      
      
          axios.get(`https://faizads.com/api/shops/${params.user}`)
          .then(res => {
            setShop(res.data)
          })
          .catch((err) => {
            console.log(err);
          })
      
      
          axios.get(`https://faizads.com/api/categories/by/username/${params.user}`)
          .then(res => {
            setCategories(res.data)
          })
          .catch((err) => {
            console.log(err);
          })
      
      
      
        }, []);
   
  return (
     

    <Fragment> 
     <TopBar shop={shop} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavbarSamaher shop={shop} shopUsername={params.user}  SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />
    <NavBar2 categories={categories} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor} />

    <center> 
    <form className="login-form" >   
    <div className='FormCover'>  
    
    <div className="about-title-english">  Customers service  </div> 
    <p className='about-paragraph-english'>
    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.


      </p>
     
     
       
        </div> 
        </form>
        </center>
        <Footer shop={shop} shopUsername={params.user} SystemBackGroundcolor={shop.map((s=>s.shop_color_code))} SystemfontColor={SystemfontColor}/>

        </Fragment>

  )
}
 
export default CustomerSupport 