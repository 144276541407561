import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const productFetch = createAsyncThunk(
  "product/productFetch",
  async () => {
    try {
      const response = await axios.get(
        "https://chaoo-online-shop.herokuapp.com/product"
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: {
    [productFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [productFetch.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.status = "success";
    },
    [productFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});
 
export default productSlice.reducer;
